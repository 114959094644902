








import Vue from "vue";

export default Vue.extend({
  model: {
    event: "modelEvent"
  },
  props: {
    label: String,
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: this.value
    };
  },
  watch: {
    selected(to) {
      this.$emit("modelEvent", to);
    },
    value(to) {
      this.selected = to;
    }
  }
});
