























import Vue, { VueConstructor } from "vue";
import mixins from "vue-typed-mixins";
import ComponentModsMixin from "@/mixins/component-mods.ts";
import Loader from "./Loader.vue";
import DropdownSvg from "@/assets/svg/dropdown.svg?inline";
import PhotoSvg from "@/assets/svg/photo.svg?inline";
import StatisticLightSvg from "@/assets/svg/statistic-light.svg?inline";

interface IComponentData {
  iconsMods: {
    [propName: string]: {
      [propName: string]: string;
    };
  };
  [propName: string]: any;
}

export const AvailableIcons: { [p: string]: VueConstructor } = {
  DropdownSvg,
  PhotoSvg,
  StatisticLightSvg
};

export default mixins(ComponentModsMixin).extend({
  name: "Button",
  baseClass: "button",
  defaultSize: "m",
  defaultTheme: "blue",
  props: {
    text: String,
    href: [Object, String],
    tag: {
      type: String,
      default: "button"
    }
  },
  data: (): IComponentData => ({
    //mods: arrow-left,arrow-right etc.
    iconsMods: {
      left: {
        arrow: "DropdownSvg",
        "photo-icon": "PhotoSvg",
        "statistic-icon": "StatisticLightSvg"
      },
      right: {
        arrow: "DropdownSvg"
      }
    }
  }),
  computed: {
    rightIcon(): string | false {
      let key = Object.keys(this.iconsMods.right).find(mod => this.containMod(mod + "-right"));
      if (key) {
        return this.iconsMods.right[key];
      }
      return false;
    },
    leftIcon(): string | false {
      let key = Object.keys(this.iconsMods.left).find(mod => this.containMod(mod + "-left"));
      if (key) {
        return this.iconsMods.left[key];
      }
      return false;
    },
    loaderTheme(): string {
      return this._theme === "blue" && !this.$attrs.disabled ? "blue" : "default";
    }
  },
  components: {
    ...AvailableIcons,
    Loader
  }
});
