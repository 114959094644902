












import Vue from "vue";

interface IOptions {
  label: string;
  [propName: string]: any;
}

export default Vue.extend({
  props: {
    options: {
      required: true,
      type: Array as () => IOptions[]
    },
    value: Object as () => IOptions
  },
  data: function() {
    return {
      selectedLabel: this.value?.label || this.options[0].label
    };
  },
  computed: {
    selectedOption(): IOptions | undefined {
      return this.options.find(option => option.label === this.selectedLabel);
    }
  },
  mounted() {
    if (!this.value) {
      this.$emit("input", this.selectedOption);
    }
  },
  watch: {
    selectedLabel(to: IOptions["label"]) {
      this.$emit("input", this.selectedOption);
    },
    value(to: IOptions) {
      this.selectedLabel = to.label;
    }
  }
});
