import { AugmentedRouterQuery } from "@/store/modules/routeQuery";
import { Dictionary, compact, memoize } from "lodash";

export const removeNullQueries = memoize(
  (query: AugmentedRouterQuery): Dictionary<string | string[]> => {
    var result: Dictionary<string | string[]> = {};
    for (let [key, value] of Object.entries(query)) {
      if (Array.isArray(value)) {
        let filteredArray = compact(value);
        if (filteredArray.length) {
          result[key] = filteredArray;
        }
      } else if (value) {
        result[key] = value;
      }
    }
    return result;
  }
);
