






import Vue from "vue";
import Layout from "./Layout.vue";
import { ResetPasswordForm } from "@/components/forms";
import { IHandlerEvent } from "@/types/modal";

export default Vue.extend({
  components: {
    Layout,
    ResetPasswordForm
  },
  data: () => ({
    email: "",
    resetSuccess: false
  }),
  methods: {
    resetSuccessHandler() {
      this.resetSuccess = true;
    },
    beforeModalOpen(event: IHandlerEvent) {
      if (event.params && event.params.email) {
        this.email = event.params.email;
      }
    },
    beforeModalClose() {
      if (this.resetSuccess) {
        this.redirectToLogin();
      }
    },
    showLogin() {
      this.$modal.hide("resetPassword");
    },
    redirectToLogin() {
      this.$router.push({
        name: "login",
        query: { to: this.$route.fullPath, from: this.$route.fullPath }
      });
    }
  }
});
