
import { RouteQueryModule, AugmentedRouterQuery } from "@/store/modules/routeQuery";
import { isEqual } from "lodash";
import { Route } from "vue-router";
import Vue from "vue";
import { removeNullQueries } from "@/utils/router-query";

//TODO: можно ли вынести управление роутом в стор?
export default Vue.extend({
  name: "QueryWatcher",
  render: h => h("div"),
  methods: {
    setQuery() {
      let commonQueries = { ...this.$route.query, ...this.globalRouterQuery };
      let cleared = removeNullQueries(commonQueries);
      if (!isEqual(cleared, this.$route.query)) {
        this.$router.replace({ query: cleared }).catch(err => {
          console.log("navigation warning: ", err);
        });
      }
    }
  },
  computed: {
    globalRouterQuery(): RouteQueryModule["query"] {
      return this.$cStore.routeQuery.query;
    }
  },
  watch: {
    globalRouterQuery(newval, oldVal) {
      this.setQuery();
    }
  }
});
