

































import Vue from "vue";
import { Link as cLink, Button as cButton, Input as cInput } from "@/ui";
import { required, minLength, email } from "vuelidate/lib/validators";
export default Vue.extend({
  components: {
    cLink,
    cButton,
    cInput
  },
  props: {
    email: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      form: {
        email: this.email,
        password: ""
      },
      backendError: false,
      showErrors: false,
      disabled: false
    };
  },
  computed: {
    emailErrorMessage(): string {
      if (this.$v.form === undefined || this.$v.form.email === undefined) return "";
      if (!this.$v.form.email.required) return "Введите E-mail";
      return "";
    },
    passwordErrorMessage(): string {
      if (this.$v.form === undefined || this.$v.form.password === undefined) return "";
      if (!this.$v.form.password.required) return "Введите пароль";
      if (this.backendError) return "Неверный E-mail или пароль";
      return "";
    }
  },
  methods: {
    async sendForm() {
      if (this.$v.$invalid) {
        this.showErrors = true;
        return;
      }
      this.disabled = true;

      const success = await this.$cStore.user.login({
        username: this.form.email,
        password: this.form.password
      });
      if (success) {
        this.resetForm();
        this.$emit("success");
      } else {
        this.disabled = false;
        this.showErrors = true;
        this.backendError = true;
      }
    },
    resetForm() {
      // this.disabled = false;
      // this.showErrors = false;
      // this.backendError = false;
    }
  },
  validations: {
    form: {
      email: { required },
      password: { required }
    }
  },
  watch: {
    email(value) {
      this.form.email = value;
    },
    "form.email"(value) {
      this.$emit("update:email", value);
    }
  }
});
