import Vue from "vue";
import App from "./App.vue";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { useStore } from "vuex-simple";
const VueMoment = require("vue-moment");
import moment from "moment";
import "moment/locale/ru";
import "moment-timezone";

const VTooltip = require("v-tooltip");
import VModal from "vue-js-modal";
import VueLazyload from "vue-lazyload";
const VueInputMask = require("vue-inputmask").default;
import declOfNum from "@/filters/declOfNum";
import vuelidate from "vuelidate";
import SmoothScrollbar from "vue-smooth-scrollbar";
import VueMeta from "vue-meta";

Vue.use(vuelidate);
Vue.use(VTooltip);
Vue.use(VueMoment, { moment });
Vue.use(VModal);
Vue.use(VueLazyload);
Vue.use(VueInputMask);
Vue.use(SmoothScrollbar);
Vue.use(VueMeta);
Vue.config.productionTip = false;
Vue.filter("declOfNum", declOfNum);
Vue.prototype.$cStore = useStore(store);

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://d18e6fe563834a39bd3719d8d51696bf@o288914.ingest.sentry.io/5393095",
    integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
    release: `metaco@${process.env.VUE_APP_SENTRY_VERSION}`
  });
  console.log(`Sentry version: metaco@${process.env.VUE_APP_SENTRY_VERSION}`);
}
export const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
