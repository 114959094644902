import { Middleware } from "..";
import { isEqual } from "lodash";
import { removeNullQueries } from "@/utils/router-query";
import { Location } from "vue-router";

export const saveQuery: Middleware = ({ to, from, store }) => {
  const storeQuery = removeNullQueries(store.routeQuery.query || {});
  const query = {
    ...storeQuery,
    ...removeNullQueries(to.query)
  };
  //есть шанс что какая-то информация потеряется(см Route vs Location interfaces)
  if (!isEqual(to.query, query)) {
    const newRoute: Location = {
      name: to.name ? to.name : undefined,
      path: to.path,
      hash: to.hash,
      params: to.params,
      query,
      replace: true
    };
    return newRoute;
  }
};
