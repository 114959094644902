import { State, Mutation, Getter } from "vuex-simple";
import { Employee, SearchSuggestion, SearchSuggestionsType } from "@/schemas/dashboard";
import { find, matches } from "lodash";

export class EmployeeSearchModule {
  @State()
  public allEmployees: Employee[] = [];

  @State()
  public dirtySelectedSuggestions: SearchSuggestion[] = [];

  @Getter()
  public get selectedSuggestions(): SearchSuggestion[] {
    return this.dirtySelectedSuggestions || [];
    // const filterdEls = this.dirtySelectedSuggestions.filter(el => {
    //   return !!find(this.availableSuggestions, matches(el));
    // });
    // const uniqSugestions = uniqWith(filterdEls, isEqual);
    // return uniqSugestions;
  }

  @Getter()
  public get filteredEmployees(): Employee[] {
    if (this.selectedSuggestions.length === 0) {
      return this.allEmployees;
    }
    return this.allEmployees.filter(employee => {
      return this.selectedSuggestions.some(suggestion => {
        let employeeCompareValue = "";
        switch (suggestion.object_type) {
          case SearchSuggestionsType.EmployeePosition:
            employeeCompareValue = employee.position.name;
            break;
          case SearchSuggestionsType.Employee:
            employeeCompareValue = this.concatEmployeeName(employee);
            break;
        }
        return suggestion.match === employeeCompareValue;
      });
    });
  }

  @Getter()
  public get availableSuggestions(): SearchSuggestion[] {
    const suggestions: SearchSuggestion[] = [];
    this.allEmployees.forEach(employee => {
      const candidatesSuggestions: SearchSuggestion[] = [
        {
          match: this.concatEmployeeName(employee),
          object_type: SearchSuggestionsType.Employee
        },
        {
          match: employee.position.name,
          object_type: SearchSuggestionsType.EmployeePosition
        }
      ];

      candidatesSuggestions.forEach(candidateS => {
        const isPrevAdded = find(suggestions, matches(candidateS));
        if (!isPrevAdded) {
          suggestions.push(candidateS);
        }
      });
    });

    return suggestions;
  }

  @Getter()
  public get getEmployee(): (id: number) => Employee | undefined {
    return id => this.allEmployees.find(employee => employee.id === id);
  }

  @Getter()
  public get concatEmployeeName(): (employee: Employee) => string {
    return employee => `${employee.last_name} ${employee.first_name} ${employee.middle_name}`;
  }

  @Mutation()
  SET_ALL_EMPLOYEES(employees: Employee[]): void {
    this.allEmployees = employees.reduce((uniqueEmployees, employee) => {
      if (!find(uniqueEmployees, matches(employee))) {
        uniqueEmployees.push(employee);
      }
      return uniqueEmployees;
    }, [] as Employee[]);
  }

  @Mutation()
  SET_SUGGESTIONS(suggestion: SearchSuggestion[] = []) {
    //здесь могу прийти подсказки которых нет в available, для этого существует геттер очищенных подсказок
    this.dirtySelectedSuggestions = suggestion;
  }
}
