import { State, Mutation, Action, Getter } from "vuex-simple";
import { ScheduleRecord } from "@/schemas/dashboard";
import {
  GetScheduleProps,
  getSchedule,
  deleteScheduleRecord,
  updateScheduleRecord,
  removeScheduleRecord,
  createScheduleRecord
} from "@/api/dashboard";
import moment, { Moment } from "moment";

export enum ListState {
  "Loading" = "Loading",
  "Empty" = "Empty",
  "Error" = "Error",
  "Ready" = "Ready"
}

export class ScheduleModule {
  @State()
  public shceduleRecords: ScheduleRecord[] = [];

  @State()
  public maxWeeksShift = 4;

  @State()
  public minWeeksShift = -4;

  @State()
  public weeksCounter = 0;

  @State()
  public weeksReady = true;

  @State()
  public lastFetchProps: GetScheduleProps | null = null; //for re-fetch records

  @State()
  state: ListState = ListState.Loading;

  @Getter()
  get isLoading(): boolean {
    return this.state === ListState.Loading;
  }

  @Getter()
  get isEmpty(): boolean {
    return this.state === ListState.Empty;
  }

  @Getter()
  get isError(): boolean {
    return this.state === ListState.Error;
  }

  @Getter()
  get isReady(): boolean {
    return this.state === ListState.Ready;
  }

  @Getter()
  public get fromDate(): Moment {
    let now = moment().startOf("week");
    let changeMethod: "add" | "subtract" = this.weeksCounter > 0 ? "add" : "subtract";
    return now[changeMethod](Math.abs(this.weeksCounter), "weeks");
  }

  @Getter()
  public get tillDate(): Moment {
    return this.fromDate
      .clone()
      .add(6, "days")
      .endOf("day");
  }

  @Getter()
  public get nextWeekExists(): boolean {
    return true;
    // return this.weeksCounter < this.maxWeeksShift;
  }

  @Getter()
  public get prevWeekExists(): boolean {
    return true;
    // return this.weeksCounter > this.minWeeksShift;
  }

  @Mutation()
  SET_LIST_STATE(newState: ListState) {
    this.state = newState;
  }

  @Mutation()
  SET_RECORDS(records: ScheduleRecord[]) {
    this.shceduleRecords = records;
  }

  @Mutation()
  UPDATE_RECORD({ id, record }: { id: number; record: ScheduleRecord }): void {
    let savedRecord = this.shceduleRecords.find(record => record.id === id);
    if (!savedRecord) {
      console.log(`UPDATE_RECORD: record ${id} now found`);
      return;
    }
    this.REMOVE_RECORD(id);
    this.INSERT_RECORD(record);
    // возможно реактивности не будет в таком случае
    // Object.assign(savedRecord, newRecord);
  }

  @Mutation()
  INSERT_RECORD(record: ScheduleRecord) {
    this.shceduleRecords.push(record);
  }

  @Mutation()
  REMOVE_RECORD(id: number) {
    this.shceduleRecords = this.shceduleRecords.filter(record => record.id !== id);
  }

  @Mutation()
  SAVE_PROPS(props: GetScheduleProps | null) {
    this.lastFetchProps = props;
  }

  @Mutation()
  SHIFT_WEEK(weekIndex: number, force = false) {
    this.weeksCounter = weekIndex;
    // this.weeksCounter = force
    //   ? weekIndex
    //   : Math.max(this.minWeeksShift, Math.min(weekIndex, this.maxWeeksShift));
  }

  @Mutation()
  SET_WEEKS_STATUS(isReady: boolean) {
    this.weeksReady = isReady;
  }

  @Action()
  async getSchedule(props: GetScheduleProps) {
    this.SET_LIST_STATE(ListState.Loading);
    try {
      const records = await getSchedule(props);
      this.SET_RECORDS(records);
      this.SAVE_PROPS(props);
      this.SET_LIST_STATE(ListState.Ready);
    } catch (err) {
      this.SET_LIST_STATE(ListState.Error);
      console.log("Fetch schedule error: ", err);
    }
  }

  @Action()
  async repeatGettingSchedule() {
    if (!this.lastFetchProps) return;
    await this.getSchedule(this.lastFetchProps);
  }

  @Action()
  async createRecord(record: Omit<ScheduleRecord, "id">): Promise<boolean> {
    try {
      const newRecord = await createScheduleRecord(record);
      this.INSERT_RECORD(newRecord);
      return true;
    } catch (err) {
      console.log("Create schedule record error ", record, err);
    }
    return false;
  }

  @Action()
  async updateRecord({
    id,
    record
  }: {
    id: number;
    record: Omit<ScheduleRecord, "id">;
  }): Promise<boolean> {
    try {
      const updatedRecord = await updateScheduleRecord(id, record);
      this.UPDATE_RECORD({ id: updatedRecord.id, record: updatedRecord });
      return true;
    } catch (err) {
      console.log("Update Schedule record error: ", err);
    }
    return false;
  }

  @Action()
  async removeRecord(id: number): Promise<boolean> {
    try {
      await removeScheduleRecord(id);
      this.REMOVE_RECORD(id);
      return true;
    } catch (err) {
      console.log("remove schedule record error: ", err);
    }
    return false;
  }

  @Action()
  shiftWeek(direction: "next" | "prev") {
    let weekIndex: number;
    if (direction === "next") {
      weekIndex = this.weeksCounter + 1;
      // weekIndex = Math.min(this.weeksCounter + 1, this.maxWeeksShift);
    } else {
      weekIndex = this.weeksCounter - 1;
      // weekIndex = Math.max(this.weeksCounter - 1, this.minWeeksShift);
    }
    this.SHIFT_WEEK(weekIndex);
  }
}
