














import Vue from "vue";
import mixins from "vue-typed-mixins";
import ComponentModsMixin from "@/mixins/component-mods.ts";

export default mixins(ComponentModsMixin).extend({
  baseClass: "link",
  defaultSize: "m",
  defaultTheme: "blue",
  props: {
    text: String,
    href: [Object, String]
  }
});
