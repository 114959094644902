



















import Vue from "vue";
import { IDownloadButtonTooltips } from "@/types/DownloadButton.ts";

import Template_svg from "@/assets/svg/template.svg?inline";
import Employee_svg from "@/assets/svg/employee-color.svg?inline";
import EmployeeBorder_svg from "@/assets/svg/employee-border.svg?inline";
import DayIcon_svg from "@/assets/svg/day-icon.svg?inline";
import Wrap from "./Wrap.vue";

export default Vue.extend({
  props: {
    items: {
      type: Array as () => IDownloadButtonTooltips[]
    }
  },
  components: {
    Template_svg,
    Employee_svg,
    EmployeeBorder_svg,
    DayIcon_svg,
    Wrap
  },
  methods: {
    getIcon(iconName: string | undefined): string | undefined {
      if (!iconName) return undefined;
      let icons: { [p: string]: string } = {
        template: "Template_svg",
        employee: "Employee_svg",
        employeeBorder: "EmployeeBorder_svg",
        dayIcon: "DayIcon_svg"
      };
      return icons[iconName];
    }
  }
});
