





























import Vue from "vue";
import Layout from "./Layout.vue";
import { IHandlerEvent } from "@/types/modal";
import { LoginForm, ResetPasswordForm } from "@/components/forms";

import axios from "axios";

export default Vue.extend({
  components: {
    Layout,
    LoginForm,
    ResetPasswordForm
  },
  data: () => ({
    redirectTo: "/",
    showResetPassword: false,
    email: "",
    openedCounter: 0
  }),
  methods: {
    beforeOpen(event: IHandlerEvent) {
      if (event.params && event.params.to) {
        this.redirectTo = event.params.to;
      }
    },
    loginSuccess() {
      this.$router.replace(this.redirectTo);
    }
  }
});
