import Vue from "vue";
import { Route, RawLocation, RouteRecord, NavigationGuardNext } from "vue-router";
import { MyStore } from "@/store/store";

export { default as Auth } from "./auth";

export type ResponseType = RawLocation | false | undefined | void | Error;

export interface MiddlewareContext {
  to: Route;
  from: Route;
  store: MyStore;
  routeRecord: RouteRecord;
}
export type Middleware = (context: MiddlewareContext) => ResponseType | Promise<ResponseType>;

export class MiddlewerePipeline {
  globalMiddleweresBefore: Middleware[] = [];
  globalMiddleweresAfter: Middleware[] = [];

  constructor(
    globalMiddleweresBefore?: Middleware[] | null,
    globalMiddleweresAfter?: Middleware[] | null
  ) {
    if (globalMiddleweresBefore) {
      this.globalMiddleweresBefore = globalMiddleweresBefore;
    }
    if (globalMiddleweresAfter) {
      this.globalMiddleweresAfter = globalMiddleweresAfter;
    }
  }

  getMiddlewares(to: Route): { routeRecord: RouteRecord; middlewares: Middleware[] }[] {
    return to.matched
      .map(match => {
        let middlewares: Middleware[];
        if (
          Array.isArray(match.meta.middlewares) &&
          match.meta.middlewares.every((m: any) => typeof m === "function")
        ) {
          middlewares = match.meta.middlewares as Middleware[];
        } else {
          middlewares = [];
        }
        return {
          routeRecord: match,
          middlewares: [
            ...this.globalMiddleweresBefore,
            ...middlewares,
            ...this.globalMiddleweresAfter
          ]
        };
      })
      .filter(m => m.middlewares.length !== 0);
  }
  async applyMiddlewares(to: Route, from: Route) {
    const matchedMiddlewares = this.getMiddlewares(to);
    for await (const middlewares of matchedMiddlewares) {
      const context = {
        to,
        from,
        store: Vue.prototype.$cStore,
        routeRecord: middlewares.routeRecord
      };
      for await (const middleware of middlewares.middlewares) {
        const nextRoute = await middleware(context);
        if (nextRoute) {
          return nextRoute;
        }
      }
    }
  }
  async beforeEachCallback(to: Route, from: Route, next: NavigationGuardNext) {
    const nextRoute = await this.applyMiddlewares(to, from);
    if (nextRoute) {
      return next(nextRoute);
    } else {
      next();
    }
  }
}
