












import Vue from "vue";
import BadgeList from "./BadgeList.vue";
import { BadgesModule, BadgesGroup } from "@/store/modules/badges";
export default Vue.extend({
  components: {
    BadgeList
  },
  computed: {
    badgeStore(): BadgesModule {
      return this.$cStore.badges;
    },
    requiredGroups(): BadgesGroup[] {
      return this.badgeStore.badgesGroups;
    }
  },
  methods: {
    groupReady(group: BadgesGroup) {
      this.badgeStore.badgesLoadingSuccess(group.id);
    },
    groupError(group: BadgesGroup, err: any) {
      this.badgeStore.badgesLoadingError({ id: group.id, reason: err });
    }
  }
});
