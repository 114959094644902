
















import Vue from "vue";
import Paginate from "vuejs-paginate";
import ArrowHorizontal_raw from "@/assets/svg/arrow-horizontal.svg?raw";

export default Vue.extend({
  inheritAttrs: false,
  components: {
    Paginate
  },
  props: {
    value: Number
  },
  data: function() {
    return {
      btn: ArrowHorizontal_raw,
      page: this.value || 1
    };
  },
  computed: {
    listeners(): Record<string, Function | Function[]> {
      return { ...this.$listeners, ...{ input: () => {} } };
    }
  },
  mounted() {
    if (!this.value) {
      this.$emit("input", this.page);
    }
  },
  watch: {
    page(to: number) {
      this.$emit("input", to);
    },
    value(to: number) {
      this.page = to;
    }
  }
});
