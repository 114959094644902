import { Base64 } from "js-base64";

export function fileToBase64(file: File): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = () => {
      let base64FileString = Base64.btoa(reader.result as string);
      resolve(base64FileString);
    };
    reader.readAsBinaryString(file);
  });
}

function str2ab(str: string) {
  var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  var bufView = new Uint16Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export const base64ToBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
  const byteCharacters = Base64.atob(b64Data);
  const byteArray = str2ab(byteCharacters);
  const blob = new Blob([byteArray], { type: contentType });
  return blob;
};
