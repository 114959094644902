import { login as fecthLogin, LoginPayload, login, User_type, resetPassword } from "@/api/auth";
import { getProfile, changeProfile } from "@/api/dashboard";
import { Mutation, State, Action, Getter } from "vuex-simple";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import { DashboardUser } from "@/schemas/dashboard";

export class UserModule {
  @State()
  private auth_token: string | null = null;

  @State()
  private rolesWithEditRights = ["admin", "editor"];

  @State()
  public profile: DashboardUser | null = null;

  @Getter()
  public get isLoggedIn() {
    return !!this.auth_token;
  }

  @Getter()
  public get userCanEdit(): boolean {
    return this.profile ? this.rolesWithEditRights.includes(this.profile.role) : false;
  }

  constructor() {
    const token = localStorage.getItem("auth_token");
    this.SET_TOKEN(token);
  }

  @Mutation()
  public SET_TOKEN(auth_token: string | null) {
    this.auth_token = auth_token;
    if (auth_token !== null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${auth_token}`;
      localStorage.setItem("auth_token", auth_token);
    } else {
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem("auth_token");
    }
  }

  @Mutation()
  public SET_PROFILE(profile: DashboardUser | null) {
    this.profile = profile;
  }

  @Action()
  public async login(payload: Omit<LoginPayload, "user_type">): Promise<boolean> {
    const loginPayload: LoginPayload = Object.assign(
      { user_type: User_type.dashboard_user },
      payload
    );
    try {
      let data = await fecthLogin(loginPayload);
      this.SET_TOKEN(data.auth_token);
      return true;
    } catch (err) {
      console.log(err);
      //обработанная ошибка с сервера
      if (err.response) {
      }
      return false;
    }
  }

  @Action()
  public async logout() {
    this.SET_TOKEN(null);
    this.SET_PROFILE(null);
    Sentry.configureScope(function(scope) {
      scope.setUser(null);
    });
  }

  @Action()
  public async getProfile(): Promise<boolean> {
    try {
      const profile = await getProfile();
      this.SET_PROFILE(profile);
      Sentry.configureScope(function(scope) {
        scope.setUser({ email: profile.email, username: profile.name, id: profile.id.toString() });
      });
      return true;
    } catch (err) {
      this.SET_PROFILE(null);
    }
    return false;
  }

  @Action()
  public async resetPassword(email: string) {
    const success = await resetPassword(email);
    await this.logout();
  }

  @Action()
  public async changeProfile(payload: { name?: string; email?: string }): Promise<boolean> {
    try {
      const profile = await changeProfile(payload);
      this.SET_PROFILE(profile);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }
}
