
















































import Vue from "vue";
import { Button as cButton, Input as cInput, Link as cLink } from "@/ui";
import { IHandlerEvent } from "@/types/modal";
import { required, email } from "vuelidate/lib/validators";
import { resetPassword } from "@/api/auth";
enum LoadState {
  "Loading",
  "Ready",
  "Error"
}

interface ComponentData {
  // loadState: LoadState;
  [propName: string]: any;
}

export default Vue.extend({
  components: {
    cInput,
    cButton,
    cLink
  },
  props: {
    email: {
      type: String,
      default: ""
    }
  },
  computed: {
    profileExists(): boolean {
      return !!this.$cStore.user.profile;
    },
    emailErrorMessage(): string {
      if (this.$v.form === undefined || this.$v.form.email === undefined) return "";
      if (!this.$v.form.email.required) return "Введите E-mail";
      if (!this.$v.form.email.email) return "Неверный формат";
      if (this.backendError) return "E-mail не найден";
      return "";
    }
  },
  beforeMount() {
    console.log("MOUNT");
    if (this.$cStore.user.profile !== null) {
      this.form.email = this.$cStore.user.profile.email;
      // this.success = true;emailErrorMessagez
      // this.$cStore.user.resetPassword(this.$cStore.user.profile.email);
    }
  },
  data(): ComponentData {
    return {
      form: {
        email: this.email
      },
      success: false,
      disabled: false,
      showErrors: false,
      isLoading: false,
      backendError: false
    };
  },
  methods: {
    async sendForm() {
      if (this.$v.$invalid) {
        this.showErrors = true;
        return;
      }
      this.showError = false;
      this.isLoading = true;
      this.disabled = true;
      try {
        await this.$cStore.user.resetPassword(this.form.email);
        this.success = true;
        this.$emit("success", true);
      } catch (err) {
        console.log("reset password error: ", err);
        this.backendError = true;
        this.showErrors = true;
      }
      this.isLoading = false;
      this.disabled = false;
    }
  },
  watch: {
    email(value) {
      this.form.email = value;
    },
    "form.email"(value) {
      this.$emit("update:email", value);
    },
    success(value) {
      if (value) {
        this.$emit("success");
      }
    }
  },
  validations: {
    form: {
      email: { required, email }
    }
  }
});
