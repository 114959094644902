




















import Vue from "vue";
import mixins from "vue-typed-mixins";
import ComponentModsMixin from "@/mixins/component-mods.ts";

interface HTMLInputElement_inputmask extends HTMLInputElement {
  inputmask: {
    unmaskedvalue: () => string;
  };
}

export default mixins(ComponentModsMixin).extend({
  inheritAttrs: false,
  baseClass: "cInput",
  defaultSize: "m",
  defaultTheme: "default",
  computed: {
    listeners(): Record<string, Function | Function[]> {
      return { ...this.$listeners, ...{ input: () => {} } };
    }
  },
  props: {
    value: String,
    placeholder: String,
    errorMessage: String,
    mask: [String, Object],
    type: {
      type: String,
      default: "text"
    },
    getUnmasked: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    inputHandler(e: InputEvent) {
      let target = e.target as HTMLInputElement_inputmask;
      let value;
      if (target.inputmask && this.getUnmasked) {
        value = target.inputmask.unmaskedvalue();
      } else {
        value = target.value;
      }
      this.$emit("input", value);
    }
  }
});
