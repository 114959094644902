import { State, Mutation } from "vuex-simple";
import { Dictionary } from "vue-router/types/router";

export type AugmentedRouterQueryValue = null | undefined | string | (null | undefined | string)[];

export type AugmentedRouterQuery = Dictionary<AugmentedRouterQueryValue>;

export class RouteQueryModule {
  @State()
  query: AugmentedRouterQuery | null = null;

  @Mutation()
  ADD_QUERY(query: AugmentedRouterQuery) {
    if (!Object.keys(query).length) return;
    const newQuery = { ...this.query, ...query };
    this.query = newQuery;
  }
}
