import { UserModule } from "./modules/user";
import { TerminalModule } from "./modules/terminal";
import { ScheduleModule } from "./modules/schedule";
import { EmployeeSearchModule } from "./modules/employeeSearch";
import { RouteQueryModule } from "./modules/routeQuery";

import { Module } from "vuex-simple";
import { BadgesModule } from "./modules/badges";

export class MyStore {
  @Module()
  public terminal = new TerminalModule();

  @Module()
  public user = new UserModule();

  @Module()
  public schedule = new ScheduleModule();

  @Module()
  public routeQuery = new RouteQueryModule();

  @Module()
  public employeeSearch = new EmployeeSearchModule();

  @Module()
  public badges = new BadgesModule();
}
