












import Vue from "vue";
import { Badges } from "@/components/Badges";
import { initAuthResponseInterceptor } from "@/api/dashboard";
import {
  ProfileModal,
  LoginModal,
  ResetPasswordModal,
  ReportRecordsModal
} from "@/components/modals";
import { TerminalModule } from "@/store/modules/terminal";
import Layout from "@/components/Layout.vue";
import QueryWatcher from "@/components/QueryWatcher.vue";
import axios from "axios";

export default Vue.extend({
  name: "app",
  metaInfo() {
    const titleTemplate = this.titleTemplate as string;
    return {
      titleTemplate
    };
  },
  components: {
    ProfileModal,
    LoginModal,
    ResetPasswordModal,
    ReportRecordsModal,
    Badges,
    QueryWatcher
  },
  created() {
    initAuthResponseInterceptor(this.$cStore.user, this.$router);
  },
  computed: {
    activeTerminalName(): TerminalModule["terminalName"] {
      return this.$cStore.terminal.terminalName;
    },
    titleTemplate(): string {
      if (this.activeTerminalName && this.$cStore.terminal.checkReal()) {
        return `${this.activeTerminalName} — %s`;
      } else {
        return "%s";
      }
    }
  }
});
