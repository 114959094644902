import Vue from "vue";

interface IComponentData {
  outerMods: string[];
  baseClass: string;
}

export default Vue.extend({
  data: function(): IComponentData {
    return {
      baseClass: "",
      outerMods: [""]
    };
  },
  props: {
    //TODO: props must accepts objects and arrays like classes, {mod1:true,mod2:false},[mod1,mod2]
    mods: {
      default: "",
      type: String
    },
    size: String,
    theme: String
  },
  computed: {
    _size(): string | undefined {
      return this.size ? this.size : this.$options.defaultSize;
    },
    _theme(): string | undefined {
      return this.theme ? this.theme : this.$options.defaultTheme;
    },
    modsArray(): string[] {
      return this.mods.split(",").map(mod => mod.trim());
    },
    allMods(): string[] {
      return [...this.modsArray, ...this.outerMods];
    },
    modsClasses(): string[] {
      return [this.baseClass, ...this.modsToClass(this.allMods)];
    }
  },
  methods: {
    containMod(mod: string) {
      return this.splitMods(this.mods).includes(mod);
    },
    addMod(mods: string[] | string) {
      if (typeof mods === "string") {
        mods = [mods];
      }
      const uniqueMods = mods.map(m => m.trim()).filter(mod => !this.outerMods.includes(mod));
      this.outerMods = uniqueMods;
    },
    removeMod(mods: string[] | string) {
      if (typeof mods === "string") {
        mods = [mods];
      }
      this.outerMods = this.outerMods.filter(mod => !mods.includes(mod));
    },
    splitMods(mods: string): string[] {
      return mods ? mods.split(",").map(e => e.trim()) : [""];
    },
    modsToClass(mods: string[]): string[] {
      return mods.filter(mod => mod !== "").map(mod => `${this.baseClass}-${mod}`);
    }
  },
  created() {
    this.baseClass = this.$options.baseClass || "";
    this.$on("setMods", this.addMod);
    this.$on("removeMods", this.removeMod);
  },
  mounted() {
    if (this.baseClass === "") {
      this.baseClass = this.$el ? this.$el.getAttribute("mod-baseClass") || "" : "";
    }
  },
  render: h => h("div")
  // template: "<div></div>"
});

export const ComponentMods = Vue.extend({
  data: function(): IComponentData {
    return {
      baseClass: "",
      outerMods: [""]
    };
  },
  props: {
    //TODO: props must accepts objects and arrays like classes, {mod1:true,mod2:false},[mod1,mod2]
    mods: {
      default: "",
      type: String
    },
    size: String,
    theme: String
  },
  computed: {
    _size(): string | undefined {
      return this.size ? this.size : this.$options.defaultSize;
    },
    _theme(): string | undefined {
      return this.theme ? this.theme : this.$options.defaultTheme;
    },
    modsArray(): string[] {
      return this.mods.split(",").map(mod => mod.trim());
    },
    allMods(): string[] {
      //TODO: remove equals
      return [...new Set([...this.modsArray, ...this.outerMods])];
    },
    modsClasses(): string[] {
      return [this.baseClass, ...this.modsToClass(this.allMods)];
    }
  },
  methods: {
    containMod(mod: string) {
      return this.splitMods(this.mods).includes(mod);
    },
    addMod(mods: string[] | string) {
      if (typeof mods === "string") {
        mods = [mods];
      }
      const uniqueMods = mods.map(m => m.trim()).filter(mod => !this.outerMods.includes(mod));
      this.outerMods = uniqueMods;
    },
    removeMod(mods: string[] | string) {
      if (typeof mods === "string") {
        mods = [mods];
      }
      this.outerMods = this.outerMods.filter(mod => !mods.includes(mod));
    },
    splitMods(mods: string): string[] {
      return mods ? mods.split(",").map(e => e.trim()) : <string[]>[];
    },
    modsToClass(mods: string[]): string[] {
      return mods.filter(mod => mod !== "").map(mod => `${this.baseClass}-${mod}`);
    }
  },
  created() {
    this.baseClass = this.$options.baseClass || "";
    this.$on("setMods", this.addMod);
    this.$on("removeMods", this.removeMod);
  },
  mounted() {
    if (this.baseClass === "") {
      this.baseClass = this.$el ? this.$el.getAttribute("mod-baseClass") || "" : "";
    }
  },
  render: h => h("div")
  // template: "<div></div>"
});
