













































//TODO: объединить логику вместе с RowBodyRow.vue
import Vue from "vue";
import { Employee, ReportRecord, TerminalWithStatus } from "@/schemas/dashboard";
import Calendar_svg from "@/assets/svg/calendar.svg?inline";
import Time_svg from "@/assets/svg/time.svg?inline";
import employee from "@/components/Employee.vue";
import { Moment } from "moment";
const placeholder = require("@/assets/img/user-placeholder-orig.jpg");
export default Vue.extend({
  props: {
    employee: {
      type: Object as () => Employee
    },
    record: {
      type: Object as () => ReportRecord
    }
  },
  computed: {
    terminal(): TerminalWithStatus | undefined {
      return this.$cStore.terminal.terminals.find(terminal => {
        return terminal.id === this.record.time_records[0]?.terminal;
      });
    },
    isAbsence(): boolean {
      return (
        this.record.time_records.length === 0 ||
        this.record.time_records.every(record => !record.leaving_time)
      );
    },
    photoIn(): string {
      if (this.record.time_records.length === 0) {
        return placeholder;
      }
      return this.record.time_records[0].arrival_photo || placeholder;
    },
    photoOut(): string {
      if (this.record.time_records.length === 0) {
        return placeholder;
      }
      const lastRecord = this.record.time_records[this.record.time_records.length - 1];
      return lastRecord.leaving_photo || placeholder;
    },
    arrivalDate(): string {
      if (this.record.time_records.length === 0) {
        return "--";
      }
      const tzDate = this.terminalDate(this.record.time_records[0].arrival_time);
      return tzDate ? tzDate.format("D MMMM, ddd") : "--";
    },
    arrivalTime(): string {
      if (this.record.time_records.length === 0) {
        return "--";
      }
      const tzDate = this.terminalDate(this.record.time_records[0].arrival_time);
      return tzDate ? tzDate.format("HH:mm") : "--";
    },
    arrivalAttention(): boolean {
      if (this.record.time_records.length === 0) return false;
      const arriavalDate = this.record.time_records[0].arrival_time;
      const scheduleDate = this.record.schedule_record?.start_time;
      if (!scheduleDate) return false;
      return this.$moment(scheduleDate).diff(arriavalDate, "minutes") < 0;
    },
    leavingDate(): string {
      if (this.record.time_records.length === 0) {
        return "--";
      }
      const lastTimeRecord = this.record.time_records[this.record.time_records.length - 1];
      const tzDate = this.terminalDate(lastTimeRecord.leaving_time);
      return tzDate ? tzDate.format("D MMMM, ddd") : "--";
    },
    leavingTime(): string {
      if (this.record.time_records.length === 0) {
        return "--";
      }
      const lastTimeRecord = this.record.time_records[this.record.time_records.length - 1];
      const tzDate = this.terminalDate(lastTimeRecord.leaving_time);
      return tzDate ? tzDate.format("HH:mm") : "--";
    },
    leavingAttention(): boolean {
      if (this.record.time_records.length === 0) return false;
      const lastTimeRecord = this.record.time_records[this.record.time_records.length - 1];
      const leavingDate = lastTimeRecord.leaving_time;
      if (!leavingDate) return true;
      const scheduleDate = this.record.schedule_record?.end_time;
      if (!scheduleDate) return false;
      return this.$moment(scheduleDate).diff(leavingDate, "minutes") > 0;
    }
  },
  methods: {
    terminalDate(date?: string): Moment | null {
      if (!this.terminal || !date) return null;
      return this.$moment(date).tz(this.terminal.timezone);
    }
  },
  components: {
    Calendar_svg,
    Time_svg,
    employee
  }
});
