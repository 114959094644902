import { Auth, Middleware } from "@/middleware";
import { Route, RouteConfig } from "vue-router";
import { Employee } from "@/schemas/dashboard";

//в последнем обновлении они разделили RouteConfig и сделали приватным часть _RouteConfigBase
// interface expandedRouterConfig extends RouteConfig {
//   meta?: {
//     middleware?: Middleware[];
//     [propName: string]: any;
//   };
// }

const routes = [
  {
    path: "/login",
    name: "login",
    props: (route: Route) => ({ from: route.query.from, to: route.query.to }),
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue")
  },
  {
    path: "/",
    component: () => import(/* webpackChunkName: "schedule" */ "@/views/app/index.vue"),
    meta: {
      middlewares: [Auth]
    },
    children: [
      {
        path: "schedule",
        name: "schedule",
        component: () => import(/* webpackChunkName: "schedule" */ "@/views/app/Schedule.vue")
      },
      {
        path: "reports",
        name: "reports",
        component: () => import(/* webpackChunkName: "reports" */ "@/views/app/Reports.vue"),
        props: true
      },
      {
        path: "",
        name: "online",
        component: () => import(/* webpackChunkName: "online" */ "@/views/app/Online.vue")
      }
    ]
  },
  {
    path: "*",
    name: "pageNotFound",
    component: () => import(/* webpackChunkName: "online" */ "@/views/PageNotFound.vue")
  }
];

export default routes;
