


































import SearchIcon from "@/assets/svg/search.svg?inline";
import CloseIcon from "@/assets/svg/close.svg?inline";

import Vue from "vue";

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String
    },
    isFocused: {
      type: Boolean
    },
    placeholder: String
  },
  data: () => ({
    localValue: ""
  }),
  computed: {
    listeners(): any {
      return Object.assign({}, this.$listeners, { input: () => {}, change: () => {} });
    },
    empty(): Boolean {
      return this.localValue.length === 0;
    }
  },
  components: {
    SearchIcon,
    CloseIcon
  },
  methods: {
    enter(): void {
      this.$emit("enter");
    },
    clear(): void {
      this.localValue = "";
    }
  },
  watch: {
    localValue(to: string): void {
      this.$emit("input", to);
    },
    value(to: string): void {
      this.localValue = to;
    },
    isFocused(value) {
      if (value) {
        (this.$refs.input as HTMLElement).focus();
      }
    }
  }
});
