










import Vue from "vue";
import vSelect from "vue-select";

import exitSmall_svg from "@/assets/svg/exit-small.svg?inline";
import search_svg from "@/assets/svg/search.svg?inline";
import { SearchSuggestion } from "@/schemas/dashboard";

export default Vue.extend({
  inheritAttrs: false,
  components: {
    vSelect
  },
  data: () => ({
    selectSettings: {
      multiple: true,
      pushTags: true,
      filterable: true,
      autoscroll: true,
      components: {
        Deselect: exitSmall_svg,
        OpenIndicator: search_svg
      },
      placeholder: "Введите должность или имя",
      maxHeight: "200px"
    }
  }),
  computed: {
    selectAttrs(): any {
      return Object.assign(this.selectSettings, this.$attrs);
    }
  }
});
