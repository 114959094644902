export type UserRole = "viewer" | "editor" | "admin";

export interface DashboardUser {
  id: number;
  name: string;
  email: string;
  role: UserRole;
}

export interface ScheduleRecord {
  id: number;
  start_time: string; //ISO 806
  end_time: string; //ISO 8061
  terminal: number;
  employee: number;
}

export interface EmployeePosition {
  id: number;
  name: string;
}

export enum SearchSuggestionsType {
  "EmployeePosition" = "EmployeePosition",
  "Employee" = "Employee"
}

// export type SearchSuggestionsType = "EmployeePosition" | "Employee";

export interface SearchSuggestion {
  match: string;
  object_type: SearchSuggestionsType;
}

export interface Terminal {
  id: number;
  name: string;
  address: string;
  timezone: string;
  badge_logo: string;
}

export interface TerminalWithStatus {
  id: number;
  name: string;
  address: string;
  timezone: string;
  is_online: boolean;
  employees_online: number;
  employees_offline: number;
  last_ping?: string;
  badge_logo: string;
}

//Отработанное время
export interface TimeRecord {
  id: number;
  arrival_time: string; //ISO 8061
  arrival_photo?: string;
  leaving_time?: string; //ISO 8061
  leaving_photo?: string;
  terminal?: number;
  auto_closed: boolean;
}

export interface EmployeePosition {
  id: number;
  name: string;
}

export interface Employee {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  photo: string;
  position: EmployeePosition;
  terminals: number[];
  is_badge_printed: boolean;
  access_pin: boolean;
}

export interface EmployeeOnline {
  employee: Employee;
  schedule_record: ScheduleRecord | null;
  time_record: TimeRecord | null;
  terminal: Terminal;
  is_online: boolean;
}

export interface Report {
  employee: Employee;
  records: ReportRecord[];
  delta: string; //Время переработки/недоработки в формате HH:MM:SS. Время недоработки (отставания от графика) обозначается знаком минуса.
  num_absences: number; //количество пропусков
}

export interface ReportRecord {
  date: string; //iso
  schedule_record: ScheduleRecord | null;
  time_records: TimeRecord[];
  delta: string; //Время переработки/недоработки в формате HH:MM:SS. Время недоработки (отставания от графика) обозначается знаком минуса.
  terminal: number;
}

export interface ScheduleImportError {
  message: string;
  column: string; //номер колонки в которой произошла ошибка
  row: string; //номер строки в которой произошла ошибка
}

export type ScheduleImportErrorList = ScheduleImportError[];
