






























import Vue from "vue";
import mixins from "vue-typed-mixins";
import CrossSvg from "@/assets/svg/close.svg?inline";
import Arrow_svg from "@/assets/svg/arrow-horizontal.svg?inline";
import { IHandlerEvent } from "@/types/modal";
import ComponentModsMixin from "@/mixins/component-mods.ts";

interface modalSlotProps {
  hide: () => void;
}

export default mixins(ComponentModsMixin).extend({
  defaultSize: "m",
  defaultTheme: "default",
  baseClass: "modal",
  data: () => ({
    stopClose: false
  }),
  computed: {
    slotProps(): modalSlotProps {
      return {
        hide: this.hide
      };
    }
  },
  props: {
    name: String,
    beforeClose: Function,
    beforeOpen: Function,
    afterClose: Function,
    title: String,
    hideOverlay: Boolean
  },
  components: {
    CrossSvg,
    Arrow_svg
  },
  methods: {
    beforeOpenHandler(event: IHandlerEvent) {
      if (this.beforeOpen) {
        this.beforeOpen(event);
      }
      this.stopClose = event.params ? event.params.stopClose : false;
    },
    beforeCloseHandler(event: IHandlerEvent) {
      if (event.params && event.params.stopClose === false) {
        this.stopClose = false;
      }
      if (this.beforeClose) {
        this.beforeClose(event);
      }

      if (this.stopClose) {
        event.stop();
      }

      if (
        event.params &&
        event.params.beforeClose &&
        typeof event.params.beforeClose === "function"
      ) {
        event.params.beforeClose();
      }

      // if (this.modalParams && this.modalParams.redirectOnClose) {
      //   if (event.params && event.params.stopRedirect) return;
      //   let from = this.modalParams.from || "/";
      //   this.$router.push(from);
      // }
    },
    closed(event: any) {
      if (this.afterClose) {
        this.afterClose();
      }
    },
    hide() {
      this.$modal.hide(this.name);
    }
  }
});
