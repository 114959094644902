import { State, Mutation, Action, Getter } from "vuex-simple";
import { uniqueId, isEqual, differenceWith } from "lodash";
import { Employee } from "@/schemas/dashboard";
/*
как работают бейджи

у нас есть стор с полем requiredBadges = [{id:number, badges:[]},{id:number, badges:[]}]
компоненты пушат туда массивы пар {employee,terminal}
из совокупности этих пар возникает уникальный id

есть компонент рендера
он смотрит на requiredBadges и создает под них компоненты для рендера в цикле по id
когда он закончил он пушит в стор о готовности, компонент из requiredBadges удаляется

компонент который запрашивает смотрит за requiredBadges и внутри ищет свой id, если его нет - загрузка завершилась
можно возвращать промис и резолвить его на завершение
*/

const resolverSymbol = Symbol("resolver");
const rejectorSymbol = Symbol("rejector");

export type Badge = Employee;
export interface BadgesGroup {
  id: string;
  badges: Badge[];
  [resolverSymbol]: [() => void];
  [rejectorSymbol]: [(reason?: any) => void];
}

export class BadgesModule {
  @State()
  badgesGroups: BadgesGroup[] = [];

  @Getter()
  public get getGroup(): (id: BadgesGroup["id"]) => BadgesGroup | undefined {
    return id => this.badgesGroups.find(g => g.id === id);
  }

  @Getter()
  public get getGroupByBadges(): (badges: BadgesGroup["badges"]) => BadgesGroup | undefined {
    return badges => {
      return this.badgesGroups.find(group => {
        return differenceWith(group.badges, badges, isEqual).length === 0;
      });
    };
  }

  @Mutation()
  ADD_BADGES_GROUP(group: BadgesGroup) {
    if (!this.getGroup(group.id)) {
      this.badgesGroups.push(group);
    }
  }

  @Mutation()
  REMOVE_BADGES_GROUP(groupId: BadgesGroup["id"]) {
    this.badgesGroups = this.badgesGroups.filter(group => group.id !== groupId);
  }

  @Action()
  requireBadges(_badges: Badge[] | Badge): Promise<void> {
    let badges = !Array.isArray(_badges) ? [_badges] : _badges;
    const existingGroup = this.getGroupByBadges(badges);
    if (existingGroup !== undefined) {
      return new Promise((res, rej) => {
        existingGroup[resolverSymbol].push(res);
        existingGroup[rejectorSymbol].push(rej);
      });
    }
    const [promise, group] = this.generateBadgeGroup(badges);
    this.ADD_BADGES_GROUP(group);
    this.addAutoErrorTimer(group);
    return promise;
  }

  @Action()
  badgesLoadingSuccess(groupId: BadgesGroup["id"]) {
    const group = this.getGroup(groupId);
    if (!group) return;
    group[resolverSymbol].forEach(res => res());
    this.REMOVE_BADGES_GROUP(groupId);
  }

  @Action()
  badgesLoadingError({ id, reason }: { id: BadgesGroup["id"]; reason?: any }) {
    const group = this.getGroup(id);
    if (!group) return;
    group[rejectorSymbol].forEach(rej => rej(reason));
    this.REMOVE_BADGES_GROUP(id);
  }

  generateBadgeGroup(badges: Badge[]): [Promise<void>, BadgesGroup] {
    const id = uniqueId();
    let promiseResolver = () => {};
    let promiseRejector = (reason?: any) => {};
    const promise = new Promise<void>((res, rej) => {
      promiseResolver = res;
      promiseRejector = rej;
    });
    return [
      promise,
      {
        id,
        badges,
        [resolverSymbol]: [promiseResolver],
        [rejectorSymbol]: [promiseRejector]
      }
    ];
  }

  addAutoErrorTimer(group: BadgesGroup) {
    const timer = process.env.NODE_ENV === "test" ? 0 : 5 * 60 * 1000;
    setTimeout(() => {
      this.badgesLoadingError({ id: group.id, reason: "some wrong" });
    }, timer);
  }
}
