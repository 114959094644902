import axios, { AxiosInstance } from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "auth/"
});

export enum User_type {
  terminal = "terminal",
  dashboard_user = "dashboard_user"
}

export interface LoginPayload {
  user_type: User_type;
  username: string;
  password: string;
}

export interface LoginResponse {
  user_id: number;
  auth_token: string;
}

export const login = async (payload: LoginPayload): Promise<LoginResponse> => {
  var { data } = await axiosInstance.post<LoginResponse>("login", payload);
  return data;
};

export const resetPassword = async (email: string) => {
  await axiosInstance.post("reset-password", { email });
  // return new Promise((res, rej) => setTimeout(res, 1000));
};

export default (ctx: any) => {
  return {
    login,
    resetPassword
  };
};
