















import Vue from "vue";
import { Employee } from "@/schemas/dashboard";

export interface Badge {
  employee: Employee;
  terminalLogo: string;
  qrcode: string;
}

export default Vue.extend({
  props: {
    badge: {
      type: Object as () => Badge,
      required: true
    }
  }
});
