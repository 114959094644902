











































import Vue from "vue";
import cButton from "./Button.vue";
import { IDownloadButtonTooltips } from "@/types/DownloadButton.ts";
import ListTooltip from "@/components/Tooltips/List.vue";
import WrongTooltip from "@/components/Tooltips/Wrong.vue";

type tooltipState = "default" | "error" | "list";

interface componentData {
  tooltipState: tooltipState;
  [p: string]: string;
}

export default Vue.extend({
  components: {
    cButton,
    ListTooltip,
    WrongTooltip
  },
  props: {
    tooltipItems: {
      type: Array as () => IDownloadButtonTooltips[]
    },
    disabled: Boolean,
    error: Boolean,
    loading: Boolean
  },
  data(): componentData {
    return {
      tooltipState: "default"
    };
  },
  computed: {
    buttonMods(): string {
      const mods = ["arrow-right"];
      if (this.listDisplayed) {
        mods.push("rotateRightIcon");
      }
      if (this.loading) {
        mods.push("loading");
      }
      return mods.join(", ");
    },
    defaultDesplayed(): boolean {
      return this.tooltipState === "default";
    },
    errorDisplayed(): boolean {
      return this.tooltipState === "error";
    },
    listDisplayed(): boolean {
      return this.tooltipState === "list";
    }
  },

  methods: {
    buttonClickHandler() {
      if (this.disabled) return;

      if (!this.defaultDesplayed) {
        this.tooltipState = "default";
      } else {
        this.tooltipState = "list";
      }
    },
    itemClick(id: number) {
      this.$emit("itemClick", id);
    },
    hideList() {
      if (!this.error) {
        this.tooltipState = "default";
      }
    }
  },

  watch: {
    disabled(isDisabled: boolean) {
      this.tooltipState = "default";
    },
    error(isError: boolean) {
      if (isError) {
        this.tooltipState = "error";
      }
    }
  }
});
