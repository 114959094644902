















import Vue from "vue";
import { Employee } from "@/schemas/dashboard";
import { chunk } from "lodash";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import cBadge, { Badge as RenderBadgeData } from "./Badge.vue";
import { Badge } from "@/store/modules/badges";
import { getEmployeeQrcode } from "@/api/dashboard";

interface ComponentData {
  badgesData: RenderBadgeData[];
  [p: string]: any;
}
export default Vue.extend({
  data(): ComponentData {
    return {
      badgesData: []
    };
  },
  props: {
    badges: {
      type: Array as () => Badge[],
      required: true
    }
  },
  components: {
    cBadge
  },
  computed: {
    badgesGroups(): RenderBadgeData[][] {
      return chunk(this.badgesData, 9);
    }
  },
  methods: {
    async download() {
      let startDate = new Date();
      let doc = await this.downloadSingleCanvas();
      let endDate = new Date();
      console.log((endDate.getTime() - startDate.getTime()) / 1000);
      doc.save("badge");
    },
    async downloadSingleCanvas(): Promise<jsPDF> {
      const docW = 210;
      const docH = 297;
      const doc = new jsPDF();
      const scale = window.devicePixelRatio || 1;

      const pages = (Array.isArray(this.$refs.page)
        ? this.$refs.page
        : [this.$refs.page]) as HTMLElement[];
      let requireNewPage = false;
      for await (let page of pages) {
        const pageW = page.offsetWidth;
        const pageH = page.offsetHeight;
        if (requireNewPage) {
          doc.addPage();
        }
        const canvas = await html2canvas(page, {
          useCORS: true,
          width: pageW,
          height: pageH,
          scale
        });

        doc.addImage(canvas.toDataURL("image/jpeg"), "JPEG", 0, 0, docW, (docW / pageW) * pageH);
        requireNewPage = true;
      }
      return doc;
    },
    async loadData(): Promise<RenderBadgeData[]> {
      let promises = this.badges.map(badge => {
        let terminalLogo = this.getTerminalLogo(badge);
        return getEmployeeQrcode(badge.id).then(qrcode => ({
          employee: badge,
          terminalLogo,
          qrcode
        }));
      });
      const badges = await Promise.all(promises);
      return badges;
    },
    getTerminalLogo(employee: Employee): string {
      let logo: string | undefined;
      for (let term of employee.terminals) {
        logo = this.$cStore.terminal.getTerminalLogo(term);
        if (logo) {
          break;
        }
      }
      return logo || "";
    }
  },

  async beforeMount() {
    try {
      this.badgesData = await this.loadData();
      await new Promise(r => setTimeout(r));
      await this.download();
      this.$emit("success");
    } catch (err) {
      this.$emit("error", err);
    }
  }
});
