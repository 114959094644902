

























import Vue from "vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/ru";
import CalendarColor_svg from "@/assets/svg/calendar-color.svg?inline";
import { DateRange } from "./types";

interface IComponentData {
  localRange: DateRange;
  firstSelected: Date | null;
  [propName: string]: any;
}

export default Vue.extend({
  model: {
    prop: "range",
    event: "change"
  },
  props: {
    range: {} as () => DateRange,
    maxMonths: {
      type: Number,
      default: 3
    }
  },
  data(): IComponentData {
    let range = this.range || [null, null];
    return {
      localRange: range,
      datepickerOptions: null,
      firstSelected: range[0],
      monthDisabled: false,
      defaultOptions: {
        "show-hour": false,
        "show-time-header": false,
        range: true,
        lang: {
          monthFormat: "MMMM"
        },
        "append-to-body": false,
        "value-type": "date",
        format: "MM.DD.YYYY",
        "range-separator": "-"
        // "disabled-date": this.checkDisabledDate
      }
    };
  },
  computed: {
    displaydRange(): string {
      let [d1, d2] = this.localRange;
      if (!d1 || !d2) return "Выберите интервал";
      let date = this.$moment(d1).format("DD.MM.YYYY");
      if (d1 !== d2) {
        date += " - " + this.$moment(d2).format("DD.MM.YYYY");
      }
      return date;
    }
  },
  components: {
    DatePicker,
    CalendarColor_svg
  },
  mounted() {
    this.defaultOptions["disabled-date"] = this.checkDisabledDate;
    this.defaultOptions["shortcuts"] = [
      {
        text: "Весь месяц",
        onClick: this.selectMonth
      }
    ];
    this.datepickerOptions = this.defaultOptions;
  },
  methods: {
    checkDisabledDate(date: Date) {
      const currentDate = this.$moment(date).startOf("day");
      const max = this.$moment()
        // .subtract({ day: 1 })
        .endOf("day");
      const laterThanMaximum = max.diff(currentDate) < 0;
      let earlierThanMinimum = false;
      if (this.firstSelected) {
        const selectedDate = this.$moment(this.firstSelected)
          .startOf("day")
          .startOf("months");

        const minDate = selectedDate
          .clone()
          .subtract({ months: this.maxMonths })
          .startOf("day");
        earlierThanMinimum = currentDate.diff(minDate) < 0;
      }
      return laterThanMaximum || earlierThanMinimum;
    },
    pickDate(date: Date) {
      if (this.firstSelected) {
        this.firstSelected = null;
      } else {
        this.firstSelected = date;
      }
    },
    selectMonth(component: any) {
      try {
        const calendar = component.$refs.picker.calendars[0];
        const monthStart = new Date(calendar.getFullYear(), calendar.getMonth(), 1);
        const monthEnd = new Date(calendar.getFullYear(), calendar.getMonth() + 1, 0);

        if (this.checkDisabledMonth(monthStart)) {
          console.log("disabled month");
          return;
        }
        let endDate = this.checkDisabledDate(monthEnd) ? new Date() : monthEnd;
        return [monthStart, endDate];
      } catch {}
    },
    checkDisabledMonth(monthDate: Date) {
      const monthStart = new Date(monthDate.getFullYear(), monthDate.getMonth(), 1);
      const monthEnd = new Date(monthDate.getFullYear(), monthDate.getMonth() + 1, 0);
      return this.checkDisabledDate(monthStart) && this.checkDisabledDate(monthStart);
    },
    callendarChanged(monthDate: Date) {
      this.monthDisabled = this.checkDisabledMonth(monthDate);
    }
  },
  watch: {
    range(val: DateRange) {
      this.localRange = val;
    },
    localRange(val: DateRange) {
      this.$emit("change", val);
    }
  }
});
