import Vue from "vue";
import VueRouter, {
  Route,
  RouteRecord,
  NavigationGuard,
  NavigationGuardNext,
  Location
} from "vue-router";
import routes from "./routes";
import { MiddlewerePipeline } from "@/middleware";
import { saveQuery } from "@/middleware/global/saveQuery";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const pipeline = new MiddlewerePipeline(null, [saveQuery]);

router.beforeEach(pipeline.beforeEachCallback.bind(pipeline));

export default router;
