














import Vue from "vue";
import mixins from "vue-typed-mixins";

const placeholder = require("@/assets/img/user-placeholder.png");
import ComponentModsMixin from "@/mixins/component-mods.ts";
import { Employee } from "@/schemas/dashboard";

interface ILazyPhoto {
  src: string | undefined;
  error: string;
  loading: string;
}

export default mixins(ComponentModsMixin).extend({
  baseClass: "employee",
  props: {
    employee: {
      type: Object as () => Employee,
      required: true
    }
  },
  computed: {
    lazyPhoto(): ILazyPhoto {
      return {
        src: this.employee.photo || placeholder,
        error: placeholder,
        loading: placeholder
      };
    }
  }
});
