











import Vue from "vue";
import mixins from "vue-typed-mixins";
import vSelect, { VueSelectProps } from "vue-select";
import "vue-select/dist/vue-select.css";
import dropdownGray_svg from "@/assets/svg/dropdown-gray.svg?inline";
import exitSmall_svg from "@/assets/svg/exit-small.svg?inline";
import search_svg from "@/assets/svg/search.svg?inline";
import ComponentModsMixin from "@/mixins/component-mods.ts";
import { merge } from "lodash";

export default mixins(ComponentModsMixin).extend({
  baseClass: "select",
  inheritAttrs: false,
  model: {
    event: "modelEvent"
  },
  props: {
    // selectSettings: {
    //   type: Object as ()=> Partial<VueSelectProps>
    // },
    // value: {
    //   type: [Array as ()=> VueSelectProps["options"], Object ]
    // }
    value: {
      type: Object as () => any
    },
    errorMessage: String
  },
  data: function() {
    return {
      selectedValue: this.value,
      defaultSelectSettings: {
        multiple: false,
        pushTags: true,
        searchable: false,
        placeholder: "Введите должность или имя",
        maxHeight: "200px"
      }
      // selectedOptions: this.value || [],
    };
  },
  computed: {
    expandedClasses(): string[] {
      let classes = this.modsClasses;
      if (this.selectAttrs.multiple) {
        classes.push("select-multiple");
      }
      return classes;
    },
    selectAttrs(): any {
      let commonSettings = merge(this.defaultSelectSettings, this.$attrs);
      let dynamicSettings = {
        components: {
          Deselect: commonSettings.multiple ? exitSmall_svg : () => {},
          OpenIndicator: commonSettings.searchable ? search_svg : dropdownGray_svg
        }
      };
      return merge(dynamicSettings, commonSettings);
    }
  },
  mounted() {
    this.checkMods();
  },
  components: {
    vSelect
  },
  methods: {
    checkMods() {
      if (this.selectAttrs.multiple) {
        this.addMod("multiple");
      } else {
        this.removeMod("multiple");
      }

      if (this.selectAttrs.searchable) {
        this.addMod("search");
      } else {
        this.removeMod("search");
      }
    }
  },
  watch: {
    selectedValue(value) {
      this.$emit("modelEvent", value);
    },
    value(to: any) {
      this.selectedValue = to;
    },
    selectAttrs() {
      this.checkMods();
    }
  }
});
