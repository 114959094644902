import { Middleware } from "./index";

const authMiddleware: Middleware = ({ from: fromRoute, to: toRoute, store, routeRecord }) => {
  const to = toRoute.fullPath;
  const from = fromRoute ? fromRoute.fullPath : "/";

  if (!store.user.isLoggedIn) {
    return { name: "login", query: { to, from } };
  }
};

export default authMiddleware;
