































import Vue from "vue";
import Layout from "./Layout.vue";
import { Button as cButton, Input as cInput, Link as cLink } from "@/ui";

import { required, minLength, email, sameAs, not } from "vuelidate/lib/validators";
import { DashboardUser } from "@/schemas/dashboard";

export default Vue.extend({
  components: {
    Layout,
    cInput,
    cButton,
    cLink
  },
  data: function() {
    return {
      email: "",
      isLoading: false,
      serverError: false,
      afterCloseHandler: () => {}
    };
  },
  computed: {
    profile(): DashboardUser | null {
      return this.$cStore.user.profile;
    },
    emailErrorMessage(): string {
      if (this.$v.email === undefined) return "";
      if (!this.$v.email.required) return "Введите E-mail";
      if (!this.$v.email.email) return "Неверный формат";
      if (!this.$v.email.notSameAsSaved) return "E-email совпадает";
      return "Что-то пошло не так, попробуйте позже";
    },
    savedEmail(): string {
      return this.profile?.email || "";
    }
  },
  methods: {
    async sendForm() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      this.$v.$reset();
      this.isLoading = true;
      const success = await this.$cStore.user.changeProfile({ email: this.email });
      this.isLoading = false;
      if (success) {
        this.$modal.hide("profile");
      } else {
        this.$v.$touch();
        this.serverError = true;
      }
    },
    resetPassword() {
      if (!this.savedEmail) return;
      this.$modal.hide("profile");
      this.$modal.show("resetPassword");
    },
    beforeOpen() {
      this.email = this.savedEmail;
      this.$v.$reset();
      this.serverError = false;
      this.isLoading = false;
    },
    logout() {
      this.afterCloseHandler = () => {
        console.log("after close");
        this.afterCloseHandler = () => {};
        this.$cStore.user.logout();
        this.$router.push("/login");
      };
      this.$modal.hide("profile");
    }
  },
  validations: {
    savedEmail: {},
    serverError: { sameAs: sameAs(() => false) },
    email: {
      email,
      required,
      notSameAsSaved: not(sameAs("savedEmail"))
    }
  }
});
