import { State, Mutation, Action, Getter } from "vuex-simple";
import { TerminalWithStatus } from "@/schemas/dashboard";
import { getTerminals } from "@/api/dashboard";

export class ALL_TERMS {
  name = "Все терминалы";
}

export class TerminalModule {
  @State()
  public activeTerminal: TerminalWithStatus | ALL_TERMS | null = null;

  @State()
  public terminals: TerminalWithStatus[] = [];

  @Getter()
  public get terminalName(): string {
    return this.checkReady() ? this.activeTerminal.name : "";
  }

  @Getter()
  public get isReady(): boolean {
    return this.checkReady();
  }

  @Getter()
  public get terminalId(): number | undefined {
    return this.checkReal() ? this.activeTerminal.id : undefined;
  }

  @Getter()
  public get getTerminal(): (id: number) => TerminalWithStatus | undefined {
    return (id: number) => {
      return this.terminals.find(terminal => terminal.id === id);
    };
  }

  @Getter()
  public get getTerminalLogo(): (id: number) => string | undefined {
    return (id: number) => this.getTerminal(id)?.badge_logo;
  }

  @Getter()
  public get checkAll(): () => this is { activeTerminal: ALL_TERMS } {
    return (): this is { activeTerminal: ALL_TERMS } =>
      !!this.activeTerminal && this.activeTerminal instanceof ALL_TERMS;
  }

  @Getter()
  public get checkReal(): () => this is { activeTerminal: TerminalWithStatus } {
    return (): this is { activeTerminal: TerminalWithStatus } =>
      !!this.activeTerminal && !this.checkAll();
  }

  @Getter()
  public get checkReady(): () => this is { activeTerminal: TerminalWithStatus | ALL_TERMS } {
    return (): this is { activeTerminal: TerminalWithStatus | ALL_TERMS } =>
      this.activeTerminal !== null;
  }

  @Mutation()
  public SET_ACTIVE(terminal: TerminalModule["activeTerminal"]) {
    this.activeTerminal = terminal;
  }

  @Mutation()
  public SET_TERMINALS(terminals: TerminalModule["terminals"]) {
    this.terminals = terminals;
  }

  @Action()
  async fetchTerminals(query: string = ""): Promise<void> {
    const termianls = await getTerminals();
    this.SET_TERMINALS(termianls);
  }
}
