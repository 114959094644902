
















import Vue from "vue";
import { Button as cButton } from "@/ui";

interface IButtonsBlock {
  name: string;
  text: string;
  mods: string;
}

export default Vue.extend({
  props: {
    buttons: {
      type: Array as () => IButtonsBlock[],
      required: true
    },
    defaultName: String
  },
  data: function() {
    return {
      activeButtonName: this.defaultName || this.buttons[0].name
    };
  },
  components: {
    cButton
  },
  methods: {
    getMods(index: number) {
      let defaultMods = this.buttons[index].mods + ", widthAuto, ";
      if (index === 0) {
        defaultMods += "in-block-left";
      } else if (index === this.buttons.length - 1) {
        defaultMods += "in-block-right";
      } else {
        defaultMods += "in-block-center";
      }
      return defaultMods;
    },
    buttonClick(item: IButtonsBlock) {
      this.activeButtonName = item.name;
      this.$emit("change", this.activeButtonName);
    }
  }
});
