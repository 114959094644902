import axios, { AxiosError } from "axios";
import { uniqBy } from "lodash";

import {
  DashboardUser,
  TerminalWithStatus,
  EmployeeOnline,
  SearchSuggestion,
  ScheduleRecord,
  ScheduleImportErrorList,
  SearchSuggestionsType,
  Employee,
  Report
} from "@/schemas/dashboard";
import { Moment } from "moment";
import { fileToBase64 } from "@/utils/fileConverts";
import { UserModule } from "@/store/modules/user";
import VueRouter from "vue-router";

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL + "dashboard/"
});

axiosInstance.interceptors.request.use(config => {
  config.headers["Authorization"] = axios.defaults.headers.common["Authorization"];
  return config;
});

export const initAuthResponseInterceptor = (store: UserModule, router: VueRouter): void => {
  axiosInstance.interceptors.response.use(undefined, (error: AxiosError<any>) => {
    if (error.response && error.response.status === 401) {
      store.logout();
      router.replace({ name: "login", query: { to: router.currentRoute.name } });
    }
    return Promise.reject(error);
  });
};

export const getEmployees = async (terminal?: number): Promise<Employee[]> => {
  const { data } = await axiosInstance.get<Employee[]>("employees", { params: { terminal } });
  return data;
};

export const getEmployee = async (id: number): Promise<Employee> => {
  const { data } = await axiosInstance.get<Employee>(`employees/${id}`);
  return data;
};

export const getEmployeeQrcode = async (id: number): Promise<string> => {
  const { data } = await axiosInstance.get<string>(`employees/${id}/qrcode`);
  return data;
};

export const getProfile = async (): Promise<DashboardUser> => {
  var { data } = await axiosInstance.get<DashboardUser>("me");
  return data;
};

export const changeProfile = async (patchedProfile: {
  name?: string;
  email?: string;
}): Promise<DashboardUser> => {
  var { data } = await axiosInstance.patch<DashboardUser>("me", patchedProfile);
  return data;
};

export const getTerminals = async (): Promise<TerminalWithStatus[]> => {
  const { data } = await axiosInstance.get<TerminalWithStatus[]>("terminals");
  // return terminalWithStatus_mocs;
  return data;
};

export const getOnlineEmployee = async (terminal?: number): Promise<EmployeeOnline[]> => {
  const { data } = await axiosInstance.get<EmployeeOnline[]>("online", { params: { terminal } });
  // return employeesOnline_mocs;
  return data;
};

export interface GetScheduleProps {
  from_date: Moment;
  till_date: Moment;
  terminal?: number;
  employee?: number;
  position?: number;
}

export const getSchedule = async (props: GetScheduleProps): Promise<ScheduleRecord[]> => {
  const mappedProps = Object.assign({}, props, {
    from_date: props.from_date.format("YYYY-MM-DD"),
    till_date: props.till_date.format("YYYY-MM-DD")
  });
  const { data } = await axiosInstance.get<ScheduleRecord[]>("schedule", { params: mappedProps });
  return data;
};

export interface ScheduleInputProps {
  file: File;
  terminal: number;
}

export const scheduleImport = async (
  props: ScheduleInputProps
): Promise<false | ScheduleImportErrorList> => {
  try {
    const base64FileString = await fileToBase64(props.file);
    await axiosInstance.post("schedule/import", {
      terminal: props.terminal,
      file: base64FileString
    });
    return false;
  } catch (err) {
    console.log(err);
    if (err.response && err.response.status === 400) {
      return err.response.data;
    } else {
      return [];
    }
  }
};

export interface ExportScheduleProps {
  terminal: number;
  from_date: Moment;
  till_date: Moment;
}

export const exportSchedule = async (props: ExportScheduleProps): Promise<Blob | false> => {
  const mappedProps = Object.assign({}, props, {
    from_date: props.from_date.format("YYYY-MM-DD"),
    till_date: props.till_date.format("YYYY-MM-DD")
  });
  try {
    const { data } = await axiosInstance.get<Blob>("schedule/export", {
      params: mappedProps,
      responseType: "blob"
    });
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const deleteScheduleRecord = async (id: number): Promise<boolean> => {
  try {
    await axiosInstance.delete(`schedule/${id}`);
    return true;
  } catch (err) {
    console.log("Delete Schedule record error: ", err);
    return false;
  }
};

export const createScheduleRecord = async (
  record: Omit<ScheduleRecord, "id">
): Promise<ScheduleRecord> => {
  const { data } = await axiosInstance.post<ScheduleRecord>("schedule", record);
  return data;
};

export const updateScheduleRecord = async (
  id: number,
  record: Omit<ScheduleRecord, "id">
): Promise<ScheduleRecord> => {
  const { data } = await axiosInstance.put(`schedule/${id}`, record);
  return data;
};

export const removeScheduleRecord = async (id: number): Promise<void> => {
  await axiosInstance.delete(`schedule/${id}`);
};

export interface GetReportsProps {
  fromDate: Moment;
  tillDate: Moment;
  terminal?: number;
}

export const getReports = async ({
  fromDate,
  tillDate,
  terminal
}: GetReportsProps): Promise<Report[]> => {
  const { data } = await axiosInstance.get<Report[]>("reports", {
    params: {
      terminal,
      from_date: fromDate.format("YYYY-MM-DD"),
      till_date: tillDate.format("YYYY-MM-DD")
    }
  });
  // return reports_mocs;
  return data;
};

export enum ExportReportFormat {
  by_employee = "by_employee",
  by_date = "by_date"
}

export interface ExportReportProps {
  report_format: ExportReportFormat;
  from_date: Moment;
  till_date: Moment;
  terminal?: number;
}

export const exportReport = async (props: ExportReportProps) => {
  const mappedProps = Object.assign({}, props, {
    from_date: props.from_date.format("YYYY-MM-DD"),
    till_date: props.till_date.format("YYYY-MM-DD")
  });
  try {
    const { data } = await axiosInstance.get<Blob>("reports/export", {
      params: mappedProps,
      responseType: "blob"
    });
    return data;
  } catch (err) {
    console.log(err);
    return false;
  }
};
