


































import Vue from "vue";
import Layout from "./Layout.vue";
import Cross_svg from "@/assets/svg/close.svg?inline";
import Arrow_svg from "@/assets/svg/arrow-wrapped.svg?inline";

import { IHandlerEvent } from "@/types/modal";
import { Employee, ReportRecord } from "@/schemas/dashboard";
import EmployeeReport from "@/components/EmployeeReport.vue";

export interface RequiredProps {
  employee: Employee;
  records: ReportRecord[];
  activeIndex: number;
}
interface LayoutEvent extends IHandlerEvent {
  params: RequiredProps | undefined;
}
interface ComponentData {
  modalParams: RequiredProps | null;
  [propName: string]: any;
}

export default Vue.extend({
  components: {
    Layout,
    Cross_svg,
    Arrow_svg,
    EmployeeReport
  },
  data(): ComponentData {
    return {
      modalParams: null,
      activeIndex: null,
      displayedIndex: null
    };
  },
  computed: {
    activeRecord(): ReportRecord | undefined {
      if (!this.modalParams) return;
      return this.modalParams.records[this.activeIndex];
    },
    employee(): Employee | null {
      if (!this.modalParams) return null;
      return this.modalParams.employee;
    }
  },
  methods: {
    beforeOpen(event: LayoutEvent) {
      if (event.params !== undefined) {
        this.modalParams = event.params;
        this.activeIndex = this.modalParams.activeIndex || 0;
      }
    },
    beforeClose() {
      this.activeIndex = null;
    },
    next() {
      if (this.modalParams === null) return null;
      this.activeIndex = Math.min(this.activeIndex + 1, this.modalParams.records.length - 1);
    },
    prev() {
      this.activeIndex = Math.max(this.activeIndex - 1, 0);
    }
  },
  watch: {
    activeIndex(nextValue, oldValue) {
      this.displayedIndex = oldValue;
    }
  }
});
