











import Vue from "vue";
import Wrap from "./Wrap.vue";
import Error_svg from "@/assets/svg/error.svg?inline";
import Close_svg from "@/assets/svg/close.svg?inline";

export default Vue.extend({
  components: {
    Wrap,
    Error_svg,
    Close_svg
  },
  props: {
    title: String,
    message: String
  }
});
